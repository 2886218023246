/* import __COLOCATED_TEMPLATE__ from './consent-to-ai-processing.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { captureException } from 'embercom/lib/sentry';
import { postRequest, ResponseError } from 'embercom/lib/inbox/requests';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type RouterService from '@ember/routing/router-service';
import type FinOptInService from 'embercom/services/fin-opt-in-service';

export default class ConsentToAiProcessing extends Component {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: RouterService;
  @service declare permissionsService: $TSFixMe;
  @service declare finOptInService: FinOptInService;
  consentToLLMProcessingTask = taskFor(this._consentToLLMProcessing);

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  get isTaskRunning() {
    return (
      this.consentToLLMProcessingTask.isRunning ||
      taskFor(this.finOptInService.consentToExternalAi).isRunning
    );
  }

  @action
  consentToLLMProcessing() {
    if (this.appService.app.canUseGlobalAiOptOut) {
      return taskFor(this.finOptInService.consentToExternalAi).perform();
    } else {
      return this.consentToLLMProcessingTask.perform();
    }
  }

  @dropTask
  *_consentToLLMProcessing() {
    try {
      let permission = 'can_access_billing_settings';
      let hasPermission = this.permissionsService.currentAdminCan(permission);
      if (!hasPermission) {
        return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(permission);
      }

      yield postRequest(`/ember/fin_ingestion_consent?app_id=${this.appService.app.id}`);

      this.consentToLLMProcessingSuccessHandler();
    } catch (error) {
      this.consentToLLMProcessingErrorHandler(error);
    }
  }

  private consentToLLMProcessingSuccessHandler() {
    this.appService.app.set('hasConsentedToFinIngestion', true);

    this.notificationsService.notifyConfirmation(
      this.intl.t('ai-agent.playground.llm-processing-consent.request-success'),
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'ingestion_consent',
      object: 'playground',
    });
  }

  private consentToLLMProcessingErrorHandler(error: any) {
    if (error instanceof ResponseError && error.response.status === 409) {
      // 409 is returned if consent has already been given by another user since the page was loaded.
      return this.consentToLLMProcessingSuccessHandler();
    }

    this.notificationsService.notifyError(
      this.intl.t('ai-agent.playground.llm-processing-consent.request-error'),
    );

    captureException(new Error('Fin ingestion consent request failed'), {
      fingerprint: ['fin-testing', 'consentToFinIngestion'],
      extra: {
        appId: this.appService.app.id,
      },
      tags: {
        responsibleTeam: 'team-ai-agent-2',
        responsible_team: 'team-ai-agent-2',
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::ConsentToAiProcessing': typeof ConsentToAiProcessing;
  }
}
