/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { type AiAssistSettingsResponse } from 'embercom/lib/ai-assist';
import { request } from 'embercom/lib/inbox/requests';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
type AiAssistUpdateType =
  | 'opt_in_articles'
  | 'opt_out_articles'
  | 'opt_in_inbox'
  | 'opt_out_inbox'
  | 'opt_in_auto_fill_tickets'
  | 'opt_out_auto_fill_tickets'
  | 'turn_on_copilot'
  | 'turn_off_copilot';

enum AiAssistSettingsToToggle {
  ToggleSummarizeAndTransform = 'toggleSummarizeAndTransform',
  ToggleAutoFillTickets = 'toggleAutoFillTickets',
  ToggleAiAssistInArticle = 'toggleAiAssistInArticle',
  NoSettingToggled = '',
}

export default class AiAssistSettings extends Component<{}> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare finOptInService: FinOptInService;

  @tracked isUserAgreementModalOpen = false;
  @tracked isDisableCopilotModalOpen = false;
  @tracked settingToToggle: AiAssistSettingsToToggle = AiAssistSettingsToToggle.NoSettingToggled;

  get canUseAiAssistInArticles() {
    return this.aiAssistSettingsLoader.value?.articles ?? false;
  }

  get canUseAiAssistToSummarize() {
    return this.aiAssistSettingsLoader.value?.conversation_summarization ?? false;
  }

  get canUseAiAssistToTransform() {
    return this.aiAssistSettingsLoader.value?.text_transformations ?? false;
  }

  get canUseAiAssistToAutoFillTickets() {
    return this.aiAssistSettingsLoader.value?.auto_fill_tickets ?? false;
  }

  get isCopilotEnabled() {
    return this.aiAssistSettingsLoader.value?.copilot ?? false;
  }

  get canUseAiAssistToSummarizeAndTransform() {
    return this.canUseAiAssistToSummarize || this.canUseAiAssistToTransform;
  }

  get requiresUserAgreement() {
    return false;
  }

  get isAutoFillTicketsDisabled() {
    return (
      !this.appService.app.canUseAiAssistAutoFillTickets ||
      taskFor(this.updateAiAssistSettings).isRunning
    );
  }

  get countOfUnlimtedTeammates() {
    return this.appService.app.adminsWithUnlimitedCopilotAccess.length;
  }

  get countOfFreeTeammates() {
    return this.appService.app.adminsWithLimitedCopilotAccess.length;
  }

  get isCopilotSwitchDisabled() {
    return (
      taskFor(this.updateAiAssistSettings).isRunning ||
      (this.isCopilotEnabled && this.countOfUnlimtedTeammates > 0)
    );
  }

  @action openDisableCopilotModal() {
    this.isDisableCopilotModalOpen = true;
  }

  @action closeDisableCopilotModal() {
    this.isDisableCopilotModalOpen = false;
  }

  @action routeToTeammatesPage() {
    this.router.transitionTo('apps.app.settings.workspace.teammates');
  }

  @action async toggleSetting() {
    if (this.settingToToggle === AiAssistSettingsToToggle.ToggleSummarizeAndTransform) {
      await this.toggleSummarizeAndTransform();
    }

    if (this.settingToToggle === AiAssistSettingsToToggle.ToggleAutoFillTickets) {
      await this.toggleAutoFillTickets();
    }

    if (this.settingToToggle === AiAssistSettingsToToggle.ToggleAiAssistInArticle) {
      await this.toggleAiAssistInArticles();
    }
  }

  @action async toggleSummarizeAndTransform() {
    let update_type: AiAssistUpdateType = this.canUseAiAssistToSummarizeAndTransform
      ? 'opt_out_inbox'
      : 'opt_in_inbox';
    try {
      let confirmed = await taskFor(this.consentToAiDataProcessing).perform(update_type);
      if (!confirmed) {
        return;
      }

      let settings = await taskFor(this.updateAiAssistSettings).perform(update_type);
      this.aiAssistSettingsLoader.update(settings);
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-assist.settings.enabled.settings-updated'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @action async toggleAiAssistInArticles() {
    let update_type: AiAssistUpdateType = this.canUseAiAssistInArticles
      ? 'opt_out_articles'
      : 'opt_in_articles';
    try {
      let confirmed = await taskFor(this.consentToAiDataProcessing).perform(update_type);
      if (!confirmed) {
        return;
      }

      let settings = await taskFor(this.updateAiAssistSettings).perform(update_type);
      this.aiAssistSettingsLoader.update(settings);
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-assist.settings.enabled.settings-updated'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @action async toggleAutoFillTickets() {
    try {
      if (!this.appService.app.canUseAiAssistAutoFillTickets) {
        return;
      }

      let update_type: AiAssistUpdateType = this.canUseAiAssistToAutoFillTickets
        ? 'opt_out_auto_fill_tickets'
        : 'opt_in_auto_fill_tickets';

      let confirmed = await taskFor(this.consentToAiDataProcessing).perform(update_type);
      if (!confirmed) {
        return;
      }

      let settings = await taskFor(this.updateAiAssistSettings).perform(update_type);
      this.aiAssistSettingsLoader.update(settings);
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-assist.settings.enabled.settings-updated'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @action async toggleCopilot() {
    let update_type: AiAssistUpdateType = this.isCopilotEnabled
      ? 'turn_off_copilot'
      : 'turn_on_copilot';

    try {
      let confirmed = await taskFor(this.consentToAiDataProcessing).perform(update_type);
      if (!confirmed) {
        return;
      }

      let settings = await taskFor(this.updateAiAssistSettings).perform(update_type);

      this.aiAssistSettingsLoader.update(settings);
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-assist.settings.enabled.settings-updated'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @use aiAssistSettingsLoader = AsyncData<AiAssistSettingsResponse>(async ({ signal }) => {
    let response = await request(`/ember/ai_assist_settings?app_id=${this.appService.app.id}`, {
      signal,
    });
    return response.json();
  });

  @dropTask *updateAiAssistSettings(
    updateType: AiAssistUpdateType,
  ): TaskGenerator<AiAssistSettingsResponse> {
    return yield post(`/ember/ai_assist_settings?app_id=${this.appService.app.id}`, {
      update_type: updateType,
    });
  }

  @dropTask *consentToAiDataProcessing(updateType: AiAssistUpdateType): TaskGenerator<boolean> {
    if (
      !['turn_on_copilot', 'opt_in_inbox', 'opt_in_auto_fill_tickets', 'opt_in_articles'].includes(
        updateType,
      )
    ) {
      return true;
    }

    if (this.appService.app.canUseExternalAi) {
      return true;
    }

    let confirmed = yield this.intercomConfirmService.confirm({
      title: this.intl.t('ai-assist.settings.ai-consent-modal.title'),
      body: this.intl.t('ai-assist.settings.ai-consent-modal.body', { htmlSafe: true }),
      confirmButtonText: this.intl.t('ai-assist.settings.ai-consent-modal.confirm-button'),
    });

    if (!confirmed) {
      return false;
    }

    try {
      yield taskFor(this.finOptInService.consentToExternalAi).perform();
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      return false;
    }

    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAssist::Settings': typeof AiAssistSettings;
    'ai-assist/settings': typeof AiAssistSettings;
  }
}
